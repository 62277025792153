import React, { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../..";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { login } from "../../API/userApi";
import DangerAlert from "../../components/UI/DangerAlert/DangerAlert";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTE } from "../../utils/consts";
const Auth = observer(() => {
  const navigate = useNavigate();
  const [loginText, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const { user } = useContext(Context);
  const signIn = async () => {
    try {
      const res = await login(loginText, password);

      user.setUser(res);
      user.setIsAuth(true);

      navigate(ADMIN_ROUTE);
    } catch (e) {
      setErrMessage(e.response.data.message);
      setShowAlert(true);
    }
  };
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      {showAlert && (
        <DangerAlert
          show={showAlert}
          onHide={() => setShowAlert(false)}
          title={"При входе произошла ошибка"}
          message={errMessage}
        />
      )}
      <Card className="p-5" style={{ width: "700px" }}>
        <h1 className="text-center">Вход в админ панель</h1>
        <Form className="d-flex flex-column">
          <FloatingLabel
            controlId="floatingInput"
            label="Логин"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Логин"
              value={loginText}
              onChange={(e) => setLogin(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Пароль">
            <Form.Control
              type="password"
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FloatingLabel>

          <Row className="d-flex justify-content-between mt-3">
            <Col className="text-end">
              <Button variant="outline-success" onClick={signIn}>
                Войти
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Container>
  );
});

export default Auth;
