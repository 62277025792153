import React, { useEffect, useState } from "react";
import s from "./Buttons.module.css";
import { addButton, getButton } from "../../API/buttonApi";
import ButtonsCard from "./ButtonsCard/ButtonsCard";

function Buttons() {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    fetchButtons();
  }, []);

  const fetchButtons = async () => {
    const getedButtons = await getButton();
    setButtons(getedButtons);
  };

  const addButtons = async () => {
    const newButton = {
      text: "Кнопка",
      link: "#",
      color: "#09234d",
      textColor: "#FFF",
      hoverColor: "#0d3575",
    };
    const addedButton = await addButton(newButton);
    console.log("addedButton", addedButton);
    setButtons((prevButtons) => [...prevButtons, addedButton]);
  };

  console.log("buttons", buttons);
  return (
    <div className={s.container}>
      <div className={s.title}>Ваши кнопки</div>
      {buttons.length !== 0 ? (
        buttons.map((button) => (
          <ButtonsCard
            button={button}
            key={button.id}
            setButtons={setButtons}
          />
        ))
      ) : (
        <div className={s.title}>Похоже кнопок еще нет...</div>
      )}
      <div className={s.addButtonContainer}>
        <div className={s.addButton} onClick={addButtons}>
          Добавить
        </div>
      </div>
    </div>
  );
}

export default Buttons;
