import { $authHost, $host } from "./index";

export const addLabel = async (label) => {
    const addedLabel = await $authHost.post('/api/label', label);
    return addedLabel;
};

export const getLabel = async () => {
  const getedLabel = await $host.get("/api/label");
  return getedLabel.data[0];
};
export const updateLabel = async (label) => {
    const updatedLabel = await $authHost.put("/api/label", label);
    return updatedLabel;
};
