import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { logout } from "../../API/userApi";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE, ADMIN_ROUTE } from "../../utils/consts";
import Banner from "../Banner/Banner";
import Wallpaper from "../Wallpaper/Wallpaper";
import Buttons from "../Buttons/Buttons";
import { useLocation } from "react-router-dom";
import SideBar from "../../components/UI/SideBar/SideBar";
import Label from "../Label/Label";
function Admin() {
  const navigate = useNavigate();

  const location = useLocation();
  const [contentComponent, setContentComponent] = useState(null);

  useEffect(() => {
    switch (location.pathname) {
      case ADMIN_ROUTE + "/banner":
        setContentComponent(<Banner />);
        break;
      case ADMIN_ROUTE + "/wallpaper":
        setContentComponent(<Wallpaper />);
        break;
      case ADMIN_ROUTE + "/label":
        setContentComponent(<Label />);
        break;
      default:
        setContentComponent(<Buttons />);
    }
  }, [location.pathname]);

  return (
    <div>
      <SideBar />
      {contentComponent}
    </div>
  );
}

export default Admin;
