import React, { useEffect, useState } from "react";
import s from "./Label.module.css";
import { addLabel, getLabel, updateLabel } from "../../API/labelApi";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { HexColorPicker } from "react-colorful";
function Label() {
  const [label, setLabel] = useState({
    headerText: "",
    description: "",
    headerTextColor: "",
    descriptionColor: "",
  });

  useEffect(() => {
    checkLabel().then(() => {
      fetchLabel();
    });
  }, []);

  const fetchLabel = async () => {
    let getedLabel = await getLabel();
    setLabel(getedLabel);
  };

  const checkLabel = async () => {
    let getedLabel = await getLabel();
    if (!getedLabel) {
      await addLabel({ headerText: "Заголовок", description: "Описание" });
      getedLabel = await getLabel();
      setLabel(getedLabel);
    }
  };

  const applyChange = async () => {
    await updateLabel(label);
  };

  return (
    <div className={s.container}>
      <div className={s.headerText}>
        <FloatingLabel label={"Заголовок"} className={s.title}>
          <Form.Control
            type="text"
            value={label.headerText}
            onChange={(e) => setLabel({ ...label, headerText: e.target.value })}
          />
        </FloatingLabel>
      </div>
      <div className={s.description}>
        <FloatingLabel label={"Описание"} className={s.descriptionText}>
          <Form.Control
            as="textarea"
            rows={3}
            value={label.description}
            onChange={(e) =>
              setLabel({ ...label, description: e.target.value })
            }
          />
        </FloatingLabel>
      </div>
      <div className={s.colorContainer}>
        <div className={s.titleColorPicker}>Цвет текста заголовка</div>
        <HexColorPicker
          color={label.headerTextColor}
          onChange={(e) => setLabel({ ...label, headerTextColor: e })}
        />
      </div>
      <div className={s.colorContainer}>
        <div className={s.titleColorPicker}>Цвет текста описания</div>
        <HexColorPicker
          color={label.descriptionColor}
          onChange={(e) => setLabel({ ...label, descriptionColor: e })}
        />
      </div>
      <Button onClick={applyChange}>Применить</Button>
    </div>
  );
}

export default Label;
