import React, { useContext } from "react";
import { adminRoutes, publicRoutes } from "../router";
import { Route, Routes, Navigate } from "react-router-dom";
import { LOGIN_ROUTE, ADMIN_ROUTE, HOME_ROUTE } from "../utils/consts";
import { observer } from "mobx-react-lite";
import { Context } from "..";
const AppRouter = observer(() => {
  const { user } = useContext(Context);

  return (
    <Routes>
      {!user.isAuth &&
        publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      {user.isAuth &&
        adminRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}

      <Route path="*" element={<Navigate replace to={HOME_ROUTE} />} />
    </Routes>
  );
});
export default AppRouter;
