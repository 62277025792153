import { $authHost, $host } from "./index";

export const getBanner = async () => {
  const getedBanner = await $host.get("api/banner");
  return getedBanner.data[0];
};

export const addBanner = async (banner) => {
  const addedBanner = await $authHost.post("api/banner", banner);
  return addedBanner;
};

export const updateBanner = async (banner) => {
  console.log("banner", banner);
  const updatedBaner = await $authHost.put("api/banner", banner);
  return updatedBaner;
};
