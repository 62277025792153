import React, { useState } from "react";
import s from "./MyButton.module.css";
function MyButton({ text, link, color, textColor, hoverColor }) {
  const [btnColor, setBtnColor] = useState(color);
  console.log('Я рисуюсь');
  return (
    <div className={s.container}>
      <div className={s.button}>
        <a
          target="_blank"
          href={link || "#"}
          style={{
            backgroundColor: btnColor,
            color: textColor,
          }}
          onMouseEnter={() => {
            setBtnColor(hoverColor);
          }}
          onMouseLeave={() => {
            setBtnColor(color);
          }}
        >
          {text}
        </a>
      </div>
    </div>
  );
}

export default MyButton;
