import React, { useEffect, useState } from "react";
import s from "./Home.module.css";
import { getBanner } from "../../API/bannerApi";
import { Button, Container } from "react-bootstrap";
import MyButton from "../../components/UI/MyButton/MyButton";
import { getButton } from "../../API/buttonApi";
import { getWallpaper } from "../../API/wallpaperApi";
import { getLabel } from "../../API/labelApi";
function Home() {
  const [banner, setBanner] = useState({ img: "" });
  const [buttons, setButtons] = useState([]);
  const [wallpaper, setWallpaper] = useState({
    img: "",
    color: "#fff",
    type: "color",
  });
  const [label, setLabel] = useState({
    headerText: "",
    description: "",
    headerTextColor: "#000",
    descriptionColor: "#000",
  });

  useEffect(() => {
    fetchBanner();
    fetchButtons();
    fetchWallpaper();
    fetchLabel();
  }, []);

  const fetchLabel = async () => {
    let getedLabel = await getLabel();
    if (!getedLabel) getedLabel = { headerText: "", description: "" };
    setLabel(getedLabel);
  };

  const fetchBanner = async () => {
    const getedBanner = await getBanner();
    setBanner(getedBanner);
  };

  console.log("wallpaper.type", wallpaper.type);
  const fetchButtons = async () => {
    const getedButtons = await getButton();
    console.log("getedButtons", getedButtons);
    setButtons(getedButtons);
  };
  const fetchWallpaper = async () => {
    const getedWallpaper = await getWallpaper();
    if (!getedWallpaper?.type) setWallpaper({ color: "#fff", type: "color" });
    else setWallpaper(getedWallpaper);
  };
  return (
    <div
      className={s.wallpaper}
      style={
        wallpaper?.type === "color"
          ? { backgroundColor: wallpaper?.color, minHeight: "100vh" }
          : {
              backgroundImage: `url(${
                process.env.REACT_APP_API_URL + wallpaper?.img
              })`,
              minHeight: "100vh",
            }
      }
    >
      <div className={s.container}>
        <div className={s.img}>
          {banner?.img !== "" ? (
            <img
              src={process.env.REACT_APP_API_URL + banner?.img}
              alt="Banner"
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className={s.header} style={{ color: label.headerTextColor }}>
          {label.headerText}
        </div>
        <div
          className={s.description}
          style={{ color: label.descriptionColor }}
        >
          {label.description}
        </div>
        <div className={s.buttons}>
          {buttons.map((button) => (
            <MyButton
              key={button.id}
              text={button.text}
              color={button.color}
              textColor={button.textColor}
              link={button.link}
              description={button.description}
              headerText={button.headerText}
              hoverColor={button.hoverColor}
              headerTextColor={button.headerTextColor}
              descriptionColor={button.descriptionColor}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
