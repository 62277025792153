import axios from "axios";

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const authInterceptor = (config) => {
  console.log(
    'localStorage.getItem("jwtToken")',
    localStorage.getItem("jwtToken")
  );
  console.log(
    'localStorage',
    localStorage
  );
  config.headers.Authorization = `Bearer ${localStorage.getItem("jwtToken")}`;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };
