import { $authHost, $host } from "./index";

export const getButton = async () => {
  const getedButton = await $host.get("api/button");
  return getedButton.data;
};

export const addButton = async (button) => {
  const addedButton = await $authHost.post("api/button", button);
  return addedButton.data;
};

export const updateButtonFn = async (button) => {
  const updatedButton = await $authHost.put("api/button", button);
  return updatedButton;
};
export const deleteButtonFn = async (id) => {
  const deleteButton = await $authHost.delete(`api/button/${id}`);
  return deleteButton;
};
