import { $authHost, $host } from "./index";

export const getWallpaper = async () => {
  const getedWallpaper = await $host.get("api/wallpaper");
  console.log("getedWallpaper", getedWallpaper);
  return getedWallpaper.data[0];
};

export const addWallpaper = async (wallpaper) => {
  const addedWallpaper = await $authHost.post("api/wallpaper", wallpaper);
  return addedWallpaper;
};

export const updateWallpaper = async (wallpaper) => {
  const updatedWallpaper = await $authHost.put("api/wallpaper", wallpaper);
  return updatedWallpaper;
};
