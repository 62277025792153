import React, { useEffect, useState } from "react";
import s from "./Banner.module.css";
import { addBanner, getBanner, updateBanner } from "../../API/bannerApi";
import { Button, Form } from "react-bootstrap";
function Banner() {
  const [banner, setBanner] = useState({ img: "" });

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0]; // Получаем выбранный файл (картинку)
    setSelectedImage(imageFile); // Сохраняем выбранную картинку в состояние
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  const fetchBanner = async () => {
    const getedBanner = await getBanner();
    setBanner(getedBanner);
  };

  const applyBanner = async () => {
    const formData = new FormData();
    formData.append("img", selectedImage);
    let newBanner;
    if (!banner) {
      newBanner = await addBanner(formData);
    } else {
      formData.append("id", banner.id);
      newBanner = await updateBanner(formData);
    }
    await fetchBanner();
  };
  return (
    <div className={s.container}>
      <div className={s.img}>
        {banner?.img !== "" ? (
          <img src={process.env.REACT_APP_API_URL + banner?.img} alt="Banner" />
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className={s.buttons}>
        <Form.Control
          type={"file"}
          accept={"image/*"}
          onChange={handleImageChange}
        />
        <Button
          className="ms-2"
          variant="success"
          onClick={applyBanner}
          disabled={!selectedImage}
        >
          Применить
        </Button>
      </div>
    </div>
  );
}

export default Banner;
