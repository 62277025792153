import Admin from "../pages/Admin/Admin";
import Auth from "../pages/Auth/Auth";
import Banner from "../pages/Banner/Banner";
import Home from "../pages/Home/Home";
import Wallpaper from "../pages/Wallpaper/Wallpaper";

import { LOGIN_ROUTE, ADMIN_ROUTE, HOME_ROUTE } from "../utils/consts";

export const publicRoutes = [
  { path: LOGIN_ROUTE, component: <Auth /> },
  { path: HOME_ROUTE, component: <Home /> },
];

export const adminRoutes = [
  { path: ADMIN_ROUTE + "/banner", component: <Admin /> },
  { path: ADMIN_ROUTE + "/wallpaper", component: <Admin /> },
  { path: ADMIN_ROUTE + "/label", component: <Admin /> },
  { path: ADMIN_ROUTE, component: <Admin /> },
  { path: LOGIN_ROUTE, component: <Auth /> },
  { path: HOME_ROUTE, component: <Home /> },
];
