import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";

export const login = async (login, password) => {
  const { data } = await $host.post("api/user/login", { login, password });
  console.log("data", data);
  localStorage.setItem("jwtToken", data.token);

  return jwt_decode(data.token);
};

export const check = async () => {
  const { data } = await $authHost.get("api/user/auth");
  console.log("data", data);
  localStorage.setItem("jwtToken", data.token);
  return jwt_decode(data.token);
};

export const logout = () => {
  localStorage.removeItem("jwtToken");
};
