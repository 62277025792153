import React, { useState } from "react";
import s from "./ButtonsCard.module.css";
import MyButton from "../../../components/UI/MyButton/MyButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import EditButtonForm from "../../../components/EditButtonForm/EditButtonForm";

function ButtonsCard({ button, setButtons }) {
  const [show, setShow] = useState(false);

  console.log('button', button);
  return (
    <div className={s.container}>
      {show ? <EditButtonForm show={show} setShow={setShow} button={button} setButtons={setButtons}/> : <></>}
      <MyButton
        headerText={button.headerText}
        description={button.description}
        text={button.text}
        link={button.link}
        color={button.color}
        textColor={button.textColor}
        hoverColor={button.hoverColor}
        headerTextColor={button.headerTextColor}
        descriptionColor={button.descriptionColor}
      />
      <FontAwesomeIcon
        icon={faPenToSquare}
        className={s.editBtn}
        onClick={() => setShow(true)}
      />
    </div>
  );
}

export default ButtonsCard;
