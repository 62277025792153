import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faLink,
  faDroplet,
  faRightFromBracket,
  faFeather
} from "@fortawesome/free-solid-svg-icons";

export const ADMIN_ROUTE = "/admin";
export const LOGIN_ROUTE = "/login";
export const HOME_ROUTE = "/";

export const SidebarData = [
  {
    title: "Баннер",
    path: ADMIN_ROUTE + "/banner",
    icon: <FontAwesomeIcon icon={faImage} />,
    cName: "nav-text",
  },
  {
    title: "Фон",
    path: ADMIN_ROUTE + "/wallpaper",
    icon: <FontAwesomeIcon icon={faDroplet} />,
    cName: "nav-text",
  },
  {
    title: "Кнопки",
    path: ADMIN_ROUTE,
    icon: <FontAwesomeIcon icon={faLink} />,
    cName: "nav-text",
  },
  {
    title: "Заголовок и Описание",
    path: ADMIN_ROUTE + '/label',
    icon: <FontAwesomeIcon icon={faFeather} />,
    cName: "nav-text",
  },
];
