import React, { useEffect, useState } from "react";
import s from "./Wallpaper.module.css";
import { SketchPicker } from "react-color";
import {
  addWallpaper,
  getWallpaper,
  updateWallpaper,
} from "../../API/wallpaperApi";
import { Button, Form } from "react-bootstrap";
function Wallpaper() {
  const [wallpaper, setWallpaper] = useState({
    img: "",
    color: "#fff",
    type: "color",
  });

  const [color, setColor] = useState("#000");
  console.log("color", color);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0]; // Получаем выбранный файл (картинку)
    setSelectedImage(imageFile); // Сохраняем выбранную картинку в состояние
  };

  useEffect(() => {
    checkWallpaper().then(() => {
      fetchWallpaper();
    });
  }, []);

  const checkWallpaper = async () => {
    const getedWallpaper = await getWallpaper();
    if (getedWallpaper) return;
    const formData = new FormData();
    formData.append("color", "#000");
    formData.append("type", "color");
    const newWallpaper = await addWallpaper(formData);
    setWallpaper(newWallpaper);
  };
  const fetchWallpaper = async () => {
    const getedWallpaper = await getWallpaper();
    setWallpaper(getedWallpaper);
    console.log("getedWallpaper3", getedWallpaper);
    setColor(getedWallpaper.color);
  };

  const applyWallpaper = async () => {
    const formData = new FormData();
    formData.append("img", selectedImage);
    let newWallpaper;
    if (!wallpaper) {
      newWallpaper = await addWallpaper(formData);
    } else {
      formData.append("id", wallpaper.id);
      newWallpaper = await updateWallpaper(formData);
    }
    await fetchWallpaper();
  };
  const applyColor = async () => {
    const formData = new FormData();
    formData.append("color", color.hex);
    let newWallpaper;
    if (!wallpaper) {
      newWallpaper = await addWallpaper(formData);
    } else {
      formData.append("id", wallpaper.id);
      newWallpaper = await updateWallpaper(formData);
    }
    await fetchWallpaper();
  };
  const changeType = async (type) => {
    const formData = new FormData();
    formData.append("type", type);
    let newWallpaper;

    formData.append("id", wallpaper.id);
    newWallpaper = await updateWallpaper(formData);

    await fetchWallpaper();
  };
  return (
    <div className={s.container}>
      <div className={s.img}>
        {wallpaper?.img !== "" ? (
          <img
            src={process.env.REACT_APP_API_URL + wallpaper?.img}
            alt="Wallpaper"
          />
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className={s.buttons}>
        <Form.Control
          type={"file"}
          accept={"image/*"}
          onChange={handleImageChange}
        />
        <Button
          className="ms-2"
          variant="success"
          onClick={applyWallpaper}
          disabled={!selectedImage}
        >
          Применить
        </Button>
      </div>
      <div className={s.buttons}>
        <SketchPicker
          color={color}
          onChangeComplete={(color) => setColor(color)}
        />

        <Button
          className="ms-2"
          variant="success"
          onClick={applyColor}
          style={{ alignSelf: "center" }}
        >
          Применить
        </Button>
      </div>
      <div className={s.chose}>
        <div className={s.title}>Выберите что отобразить</div>
        <div className={s.buttons}>
          <Button
            className="me-2"
            onClick={() => changeType("img")}
            disabled={wallpaper.img === "" ? true : false}
          >
            Картинка
          </Button>
          <Button onClick={() => changeType("color")}>Цвет</Button>
        </div>
      </div>
    </div>
  );
}

export default Wallpaper;
