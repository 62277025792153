import React, { useState } from "react";
import s from "./EditButtonForm.module.css";
import { Modal, Form, Button, FloatingLabel } from "react-bootstrap";
import { deleteButtonFn, updateButtonFn } from "../../API/buttonApi";
import { HexColorPicker } from "react-colorful";

function EditButtonForm({ show, setShow, button, setButtons }) {
  const [currentButton, setCurrentButton] = useState(button);

  console.log("currentButton", currentButton);

  const updateButton = async () => {
    setShow(false);
    const updatedButton = await updateButtonFn(currentButton);
    setButtons((prevButtons) => {
      const index = prevButtons.findIndex(
        (button) => button.id === currentButton.id
      );
      if (index !== -1) {
        // Если кнопка с таким id найдена, заменяем её на currentButton
        const updatedButtons = [...prevButtons];
        updatedButtons[index] = currentButton;
        return updatedButtons;
      } else {
        // Если кнопка с таким id не найдена, добавляем currentButton в массив
        return [...prevButtons, currentButton];
      }
    });
  };

  const deleteButton = async () => {
    setShow(false);

    await deleteButtonFn(currentButton.id);

    setButtons((prevButtons) => {
      const index = prevButtons.findIndex(
        (button) => button.id === currentButton.id
      );
      if (index !== -1) {
        // Если кнопка с таким id найдена, удаляем её из массива
        const updatedButtons = [...prevButtons];
        updatedButtons.splice(index, 1);
        return updatedButtons;
      } else {
        // Если кнопка с таким id не найдена, возвращаем исходный массив
        return prevButtons;
      }
    });
  };

  return (
    <Modal show={show}>
      <Form style={{ padding: 30 }}>
        <Modal.Header className="mb-2">
          <Modal.Title>Редактирование кнопки</Modal.Title>
        </Modal.Header>
        <FloatingLabel label={"Ссылка"} className="mb-2">
          <Form.Control
            type="text"
            value={currentButton.link}
            onChange={(e) =>
              setCurrentButton({ ...currentButton, link: e.target.value })
            }
          />
        </FloatingLabel>
        <FloatingLabel label={"Текст кнопки"} className="mb-2">
          <Form.Control
            type="text"
            value={currentButton.text}
            onChange={(e) =>
              setCurrentButton({ ...currentButton, text: e.target.value })
            }
          />
        </FloatingLabel>

        <div className={s.rowContainer}>
          <div className="mb-2">
            <div className={s.title}>Цвет кнопки</div>
            <HexColorPicker
              color={currentButton.color}
              onChange={(e) => setCurrentButton({ ...currentButton, color: e })}
            />
          </div>
          <div>
            <div className={s.title}>Цвет текста</div>
            <HexColorPicker
              color={currentButton.textColor}
              onChange={(e) =>
                setCurrentButton({ ...currentButton, textColor: e })
              }
            />
          </div>
          <div>
            <div className={s.title}>Цвет при наведении</div>
            <HexColorPicker
              className={s.block}
              color={currentButton.hoverColor}
              onChange={(e) =>
                setCurrentButton({ ...currentButton, hoverColor: e })
              }
            />
          </div>
        </div>

        <Button variant="success" onClick={updateButton}>
          Обновить
        </Button>
        <Button className="ms-2" variant="danger" onClick={deleteButton}>
          Удалить
        </Button>
        <Button
          className="ms-2"
          variant="danger"
          onClick={() => {
            setShow(false);
          }}
        >
          Закрыть
        </Button>
      </Form>
    </Modal>
  );
}

export default EditButtonForm;
